import React from "react";
import { usePredictiveTextContext } from "../contexts/PredictiveTextContext";

import styles from "./PredictiveText.module.css";

const PredictiveText: React.FC = () => {
  const { predictions, acceptPrediction } = usePredictiveTextContext();

  return (
    <div className={styles.Predictions}>
      {predictions?.length > 1 &&
        predictions.map(
          (word, idx) =>
            word && (
              <span
                className={styles.Word}
                key={idx}
                onClick={() => acceptPrediction(word)}
              >
                {word}
              </span>
            )
        )}
    </div>
  );
};

export default PredictiveText;
