import React, { useEffect, useRef, useState } from "react";

import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

import styles from "./PredictiveKeyboard.module.css";
import classnames from "classnames";
import { usePredictiveTextContext } from "../contexts/PredictiveTextContext";

const KEYBOARD_LAYOUT = {
  default: [
    "q w e r t y u i o p",
    "a s d f g h j k l",
    ". z x c v b n m ⌫",
    "! ? {space} @ #",
  ],
  shift: [
    "Q W E R T Y U I O P",
    "! A S D F G H J K L #",
    "{shift} Z X C V B N M {bksp}",
    "123 😀 {space} {enter}",
  ],
  xshift: [
    "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
    "{tab} Q W E R T Y U I O P { } |",
    '{lock} A S D F G H J K L : " {enter}',
    "{shift} Z X C V B N M < > ? {shift}",
    "# {space} !!",
  ],
};

interface IProps {
  // onChange: (input: string) => void;
  onSubmit: () => void;
  // keyboardRef: MutableRefObject<Keyboard | null>;
}
const PredictiveKeyboard: React.FC<IProps> = ({ onSubmit }) => {
  const [layout, setLayout] = useState("default");
  const keyboardRef = useRef<Keyboard>();

  const {
    text,
    addInput,
    eraseFromEnd,
    activeKeys,
  } = usePredictiveTextContext();

  const onKeyPress = (button: string) => {
    if (button === "{enter}") {
      onSubmit();
    } else if (button === "{shift}" || button === "{lock}") {
      setLayout((cur) => (cur === "shift" ? "default" : "shift"));
    } else if (button === "{space}") {
      addInput(" ");
    } else if (button === "⌫") {
      // backspace erase-to-the-left
      eraseFromEnd();
    } else {
      addInput(button);
    }
  };

  useEffect(() => {
    keyboardRef.current?.setInput(text);
  }, [text]);

  let buttonThemes = [
    { class: styles.KeyboardButtonSpace, buttons: "{space}" },
  ];

  const activeButtons = Array.from(activeKeys).join(" ");
  if (activeButtons) {
    buttonThemes.push({
      class: styles.ActiveKeys,
      buttons: activeButtons,
    });
  }

  return (
    <Keyboard
      keyboardRef={(ref) => (keyboardRef.current = ref)}
      layout={KEYBOARD_LAYOUT}
      layoutName={layout}
      // onChange={onChange}
      onKeyPress={onKeyPress}
      theme={classnames("hg-theme-default", styles.KeyboardTheme)}
      buttonTheme={buttonThemes}
    />
  );
};

export default PredictiveKeyboard;
