import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import styles from "./Chat.module.css";
import { IMessage, useChatContext } from "../contexts/ChatContext";
import PredictiveKeyboard from "./PredictiveKeyboard";
import Message from "./Message";
import classnames from "classnames";
import PredictiveText from "./PredictiveText";
import { ReactComponent as WaitingIcon } from "../assets/waiting.svg";
// import { ReactComponent as PlusIcon } from "../assets/plus-icon.svg";
import { ReactComponent as PhotoIcon } from "../assets/photo-icon.svg";
import { ReactComponent as SendIcon } from "../assets/send-icon.svg";
import { usePredictiveTextContext } from "../contexts/PredictiveTextContext";
import { useParams } from "react-router-dom";
import SharePanel from "./SharePanel";
import randomInt from "../randomInt";
// import CrossfadeImage from "react-crossfade-image";
import CrossFadeImage from "./CrossFadeImage";
import AutoCompleteInput from "./AutoCompleteInput";

enum InputType {
  Image,
  Text,
}

interface IParams {
  name: string;
}

const Chat: React.FC = () => {
  const { name: botName } = useParams<IParams>();
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [backgroundImage, setBackgroundImage] = useState("");
  const [largeImage, setLargeImage] = useState<string>();

  const [activeInputMethod, setActiveInputMethod] = useState<InputType>(
    InputType.Text
  );
  const [showInputPanel, setShowInputPanel] = useState(false);

  const {
    text: inputText,
    setSuggestions,
    setText: setInputText,
    completions,
  } = usePredictiveTextContext();

  const { useChat, userName } = useChatContext();
  const {
    bot,
    activeTrigger,
    activeBotMessage,
    botIsTyping,
    processUserMessage,
    // reset,
    showSharePanel,
  } = useChat(botName);
  const messageEnd = useRef<HTMLDivElement>(null);
  // const inputEl = useRef<HTMLInputElement>(null);

  const showImagePanel = () => {
    setShowInputPanel(true);
    setActiveInputMethod(InputType.Image);
  };

  const showKeyboard = () => {
    setShowInputPanel(true);
    setActiveInputMethod(InputType.Text);
  };

  const sendMessage = () => {
    let userText = inputText;
    if (completions?.length === 1) {
      userText += completions[0];
    }
    const userMsg: IMessage = {
      sender: "user",
      timestamp: new Date(),
      text: userText,
    };
    appendMessage(userMsg);
    processUserMessage(userMsg);
    clearInput();
  };

  const sendImage = (imgName: string) => {
    const userMsg: IMessage = {
      sender: "user",
      timestamp: new Date(),
      image: imgName,
    };
    appendMessage(userMsg);
    processUserMessage(userMsg);
    clearInput();
  };

  const appendMessage = (msg: IMessage) => {
    setMessages((msgs) => {
      return [...msgs, msg];
    });
  };

  const clearInput = () => {
    setInputText("");
    // keyboard.current?.clearInput();
  };

  useEffect(() => {
    if (!botIsTyping) {
      setSuggestions(activeTrigger?.textSuggestions?.map(({ text }) => text));
      // if (activeTrigger?.imageReactions) {
      //   setActiveInputMethod(InputType.Image);
      // } else {
      //   setActiveInputMethod(InputType.Text);
      // }
    } else {
      setSuggestions([]);
    }
    if (activeTrigger?.specials?.includes("hideInputPanel")) {
      setShowInputPanel(false);
    }
  }, [activeTrigger, botIsTyping, setSuggestions]);

  useEffect(() => {
    if (activeBotMessage) {
      appendMessage(activeBotMessage);
    }
  }, [activeBotMessage]);

  useEffect(() => {
    if (bot?.backgrounds?.length) {
      setBackgroundImage(bot.backgrounds[randomInt(0, bot.backgrounds.length)]);
      if (bot.backgrounds.length > 1) {
        // fading
        const timer = setInterval(() => {
          if (bot?.backgrounds?.length) {
            setBackgroundImage(
              bot.backgrounds[randomInt(0, bot.backgrounds.length)]
            );
          }
        }, 25000);
        return () => clearInterval(timer);
      }
    }
  }, [bot]);

  const hasSuggestions = !!activeTrigger?.textSuggestions?.length;
  useEffect(() => {
    const timer = setTimeout(() => {
      if (messageEnd.current) {
        // messageEnd.current.parentElement!.scrollTop = messageEnd.current.parentElement?.scrollHeight!;
        messageEnd.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 50);
    return () => {
      clearTimeout(timer);
    };
  }, [
    messages,
    showInputPanel,
    activeInputMethod,
    hasSuggestions,
    showSharePanel,
  ]);

  return (
    <div className={styles.ChatView}>
      <CrossFadeImage
        className={styles.Background}
        src={backgroundImage}
        duration={2000}
        delay={500}
      />
      <Header
        title={`${botName} & ${userName}`}
        backLink="/"
        image={bot?.avatar}
      />
      <div className={styles.Messages} onClick={() => setShowInputPanel(false)}>
        {messages.map((msg, idx) => (
          <Message
            key={idx}
            message={msg}
            senderName={msg.sender === "bot" ? botName : userName}
            // avatar={msg.sender === "bot" ? bot?.avatar : undefined}
            onClickImage={(image) => setLargeImage(image)}
          />
        ))}
        {botIsTyping && (
          <div className={styles.TypingIcon}>
            <WaitingIcon />
          </div>
        )}
        {showSharePanel && <SharePanel />}
        <div ref={messageEnd}></div>
      </div>
      <div className={styles.UserInputContainer}>
        <button
          disabled={
            !activeTrigger?.imageReactions ||
            activeTrigger?.imageReactions?.length === 0 ||
            botIsTyping
          }
          className={styles.PhotoButton}
          onClick={() => showImagePanel()}
        >
          <PhotoIcon />
        </button>
        <span
          className={classnames(styles.UserTextInput, {
            // [styles.TextInputDisabled]: activeTrigger?.allowUserTyping,
          })}
          onClick={(e) => showKeyboard()}
        >
          <AutoCompleteInput text={inputText} cursor={!botIsTyping} /> &nbsp;
        </span>

        <button disabled={!inputText} onClick={sendMessage}>
          <SendIcon />
        </button>
      </div>
      {showInputPanel && (
        <div className={styles.UserInputMethodsContainer}>
          {activeInputMethod === InputType.Text && (
            <>
              <PredictiveText />
              <PredictiveKeyboard
                // keyboardRef={keyboard}
                // onChange={setInputText}
                onSubmit={() => sendMessage()}
              />
            </>
          )}
          {activeInputMethod === InputType.Image && (
            <div className={styles.ImagePane}>
              {activeTrigger?.imageReactions &&
                activeTrigger.imageReactions.map(({ path }, idx) => (
                  <span key={idx} onClick={() => sendImage(path)}>
                    <img src={`/images/${path}`} alt="" />
                  </span>
                ))}
            </div>
          )}
        </div>
      )}
      {largeImage && (
        <div className={styles.LargeImage}>
          <img
            src={`/images/${largeImage}`}
            onClick={() => setLargeImage(undefined)}
          />
        </div>
      )}
    </div>
  );
};

export default Chat;
