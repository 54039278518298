import React from "react";
import { useLocation } from "react-router-dom";
import FacebookIcon from "../assets/share-facebook.png";
import TalkToMemeIcon from "../assets/share-ttm.png";
import TwitterIcon from "../assets/share-twitter.png";
import WhatsappIcon from "../assets/share-whatsapp.png";

import styles from "./SharePanel.module.css";

const SharePanel: React.FC = () => {
  //   const location = useLocation();
  //   console.log(window.location);
  const url = window.location.origin;
  const text = `I just had the weirdest chat with a KAREN.
  She would really like to talk to you.`;
  const msg = `I just had the weirdest chat with a KAREN.
She would really like to talk to you.
${url}`;

  const hasShare = !!(window.navigator as any).canShare;
  const doShare = () => {
    (window.navigator as any).share({ url, text, title: "Talk To Meme" });
  };
  const copyLink = async () => {
    try {
      await window.navigator.clipboard?.writeText(url);
    } catch (ex) {}
  };

  return (
    <div className={styles.SharePanel}>
      {hasShare ? (
        <a onClick={() => doShare()}>
          <img src={TalkToMemeIcon} alt="talktomeme" />
        </a>
      ) : (
        <a onClick={() => copyLink()}>
          <img src={TalkToMemeIcon} alt="talktomeme" />
        </a>
      )}
      <a
        target="_blank"
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url
        )}&quote=${encodeURIComponent(text)}`}
      >
        <img src={FacebookIcon} alt="facebook" />
      </a>
      <a
        target="_blank"
        href={`https://wa.me/?text=${encodeURIComponent(msg)}`}
      >
        <img src={WhatsappIcon} alt="whatsapp" />
      </a>
      <a
        href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
          msg
        )}`}
        target="_blank"
      >
        <img src={TwitterIcon} alt="twitter" />
      </a>
    </div>
  );
};

export default SharePanel;
