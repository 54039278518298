import React from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as CrossIcon } from "../assets/cross-icon.svg";
import styles from "./Header.module.css";

interface IProps {
  title?: string;
  showBack?: boolean;
  backLink?: string;
  image?: string;
}
const Header: React.FC<IProps> = ({ title, image, backLink }) => {
  const history = useHistory();
  // const goBack = () => {
  //   history.goBack();
  // };
  return (
    <div className={styles.Header}>
      {backLink && (
        <button
          className={styles.BackButton}
          onClick={() => history.push(backLink)}
        >
          <CrossIcon />
        </button>
      )}
      {image && <img src={image} className={styles.HeaderImage} />}
      <h1>{title ?? "Welcome"}</h1>
    </div>
  );
};

export default Header;
