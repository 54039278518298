import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import escapeRE from "escape-string-regexp";
import unique from "array-unique";

interface IPredictiveTextContext {
  setSuggestions: (suggestions: string[] | undefined) => void;
  addInput: (input: string) => void;
  eraseFromEnd: () => void;

  activeKeys: Set<string>;
  predictions: string[];
  completions: string[];

  acceptPrediction: (prediction: string) => void;

  text: string;
  setText: (text: string) => void;
}

const PredictiveTextContext = createContext<IPredictiveTextContext | undefined>(
  undefined
);

export const PredictiveTextProvider: React.FC = ({ children }) => {
  const [text, setText] = useState<string>("");
  const [suggestions, _setSuggestions] = useState<string[]>([]);

  const [matches, setMatches] = useState<string[]>([]);

  const [activeKeys, setActiveKeys] = useState<Set<string>>(new Set());
  const [predictions, setPredictions] = useState<string[]>([]);
  const [completions, setCompletions] = useState<string[]>([]);

  const setSuggestions = useMemo(
    () => (suggestions: string[] | undefined) => {
      _setSuggestions(suggestions || []);
    },
    []
  );

  const addInput = (input: string) => {
    if (input === " " && predictions?.length === 1) {
      acceptPrediction(predictions[0]);
    } else {
      setText((_text) => {
        return _text + input;
      });
    }
  };

  const eraseFromEnd = () => {
    setText((_text) => {
      if (text && text.length) {
        return _text.substr(0, _text.length - 1);
      }
      return _text;
    });
  };

  const acceptPrediction = (prediction: string) => {
    setText((_text) => {
      if (!_text || !_text.length) {
        return prediction + " ";
      }
      if (_text.endsWith(" ")) {
        return _text + prediction + " ";
      }
      let start = 0;
      const matchLastSpace = _text.match(/\s\S+$/);
      if (matchLastSpace) {
        start = matchLastSpace.index!;
      }
      const lastWord = _text.substring(start).trim();
      const lastWordRe = new RegExp(escapeRE(lastWord), "i");
      if (lastWordRe.test(prediction)) {
        if (start === 0) {
          return prediction + " ";
        }
        return _text.substring(0, start) + " " + prediction + " ";
      } else {
        return _text + " " + prediction + " ";
      }
    });
  };

  useEffect(() => {
    const textRE = new RegExp("^" + escapeRE(text), "i");
    setMatches(suggestions.filter((suggestion) => textRE.test(suggestion)));
  }, [suggestions, text]);

  useEffect(() => {
    if (text === "") {
      // empty input, only keys
      setPredictions([]);
      setCompletions([]);
      setActiveKeys(
        new Set(matches.map((suggestion) => suggestion.charAt(0).toLowerCase()))
      );
    } else {
      //   const ms = matches();
      if (matches.length === 0) {
        setPredictions([]);
        setCompletions([]);
        setActiveKeys(new Set());
        return;
      }
      let len = text.length;
      setActiveKeys(
        new Set(
          matches.map((match) => {
            if (match.length > len) {
              return match.charAt(len).toLowerCase();
            } else {
              return "";
            }
          })
        )
      );
      let currentWordLength = 0;
      if (text.endsWith(" ")) {
        // const len = text.length;
        // don't suggest new words
        setPredictions([]);
        setCompletions([]);
        return;
      } else {
        const m = text.match(/(^|\s)(\S+)$/);
        // matches the last space and the length of the current word
        if (m) {
          len = m.index! + m[1].length;
          currentWordLength = m[2].length;
        }
      }
      const nextWords = matches
        .map((match) => {
          const m = match.substring(len).trim().match(/^\S+/);
          if (m && m.length) {
            if (len > 12) {
              return match.substring(len);
            }
            return m[0];
          }
          return undefined;
        })
        .filter((str) => !!str) as string[];
      setCompletions(
        unique(nextWords.map((str) => str.substring(currentWordLength)))
      );
      setPredictions(unique(nextWords));
    }
  }, [text, matches]);

  return (
    <PredictiveTextContext.Provider
      value={{
        setSuggestions,
        addInput,
        eraseFromEnd,
        activeKeys,
        predictions,
        completions,
        acceptPrediction,
        text,
        setText,
      }}
    >
      {children}
    </PredictiveTextContext.Provider>
  );
};

export const usePredictiveTextContext = () =>
  useContext(PredictiveTextContext)!;

export default PredictiveTextContext;
