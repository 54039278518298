import React from "react";
import { IMessage, useChatContext } from "../contexts/ChatContext";
import styles from "./Message.module.css";
import classnames from "classnames";

interface IProps {
  message: IMessage;
  senderName: string;
  avatar?: string;
  onClickImage?: (image: string) => void;
}
const Message: React.FC<IProps> = ({
  message,
  avatar,
  senderName,
  onClickImage,
}) => {
  const { userName } = useChatContext();

  return (
    <div className={styles.MessageContainer}>
      <div
        className={classnames(styles.Message, {
          [styles.FromUser]: message.sender === "user",
          [styles.FromBot]: message.sender === "bot",
          [styles.HasImage]: !!message.image,
        })}
      >
        <label>
          {avatar && <img src={avatar} />} {senderName}
        </label>
        {message.image && (
          <p>
            <img
              src={`/images/${message.image}`}
              alt=""
              onClick={(evt) => onClickImage && onClickImage(message.image!)}
            />
          </p>
        )}
        {message.text && (
          <p
            dangerouslySetInnerHTML={{
              __html: message.text.replace("{userName}", userName),
            }}
          ></p>
        )}
      </div>
    </div>
  );
};

export default Message;
