import React, { useEffect, useState } from "react";
import { usePredictiveTextContext } from "../contexts/PredictiveTextContext";

import styles from "./AutoCompleteInput.module.css";

interface IProps {
  text: string;
  cursor: boolean;
}

const AutoCompleteInput: React.FC<IProps> = ({ text, cursor }) => {
  const { completions, acceptPrediction } = usePredictiveTextContext();
  const [completion, setCompletion] = useState<string>("");

  useEffect(() => {
    if (completions?.length === 1) {
      setCompletion(completions[0]);
    } else {
      setCompletion("");
    }
  }, [completions]);

  return (
    <>
      <span>{text}</span>
      {cursor && <span className={styles.Cursor}></span>}
      {completion && <span className={styles.Completion}>{completion}</span>}
    </>
  );
};

export default AutoCompleteInput;
