import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Chat from "./components/Chat";
import Home from "./components/Home";
import style from "./App.module.css";
import { ChatProvider } from "./contexts/ChatContext";
import { PredictiveTextProvider } from "./contexts/PredictiveTextContext";

function App() {
  return (
    <ChatProvider>
      <PredictiveTextProvider>
        <div className={style.AppWrapper}>
          <div className={style.App}>
            <Router>
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/about">
                  <Redirect to="/chat/About" />
                </Route>
                <Route path="/chat/:name">
                  <Chat />
                </Route>
              </Switch>
            </Router>
          </div>
        </div>
      </PredictiveTextProvider>
    </ChatProvider>
  );
}

export default App;
