import React from "react";
import { Link } from "react-router-dom";
import { IBot, useChatContext } from "../contexts/ChatContext";
import styles from "./Home.module.css";
import classnames from "classnames";

const Home: React.FC = () => {
  const { bots } = useChatContext();

  const botInfo = (bot: IBot) => {
    return (
      <div
        className={classnames(styles.BotInfo, {
          [styles.Inactive]: !bot.active,
        })}
      >
        <div className={styles.Avatar}>
          <img src={bot.avatar} alt="avatar" />
        </div>
        <div className={styles.BotName}>
          <h3>{bot.name}</h3>
          {!bot.active && <span>** soon online **</span>}
        </div>
      </div>
    );
  };
  return (
    <div className={styles.Home}>
      <h1>Chats</h1>
      <ul>
        {bots.map((bot) => (
          <li key={bot.name}>
            {bot.active && <Link to={`/chat/${bot.name}`}>{botInfo(bot)}</Link>}
            {!bot.active && botInfo(bot)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Home;
