import React, { createContext, useContext } from "react";
import { useStorageState } from "react-storage-hooks";

import AvatarKaren from "../assets/avatars/Karen.jpg";
import AvatarCovidiot from "../assets/avatars/Covidiot.jpg";
import AvatarAbout from "../assets/avatars/About.png";
import AvatarDoomergirl from "../assets/avatars/Doomergirl.jpg";
import AvatarSJW from "../assets/avatars/SJW.jpg";
import ChatHandler from "./ChatHandler";

import BackgroundAbout from "../assets/backgrounds/about.jpg";
import BackgroundKaren1 from "../assets/backgrounds/karen/1.jpg";
import BackgroundKaren2 from "../assets/backgrounds/karen/2.jpg";
import BackgroundKaren3 from "../assets/backgrounds/karen/3.png";
import BackgroundKaren4 from "../assets/backgrounds/karen/4.jpg";

export type TSender = "bot" | "user";

export interface IMessage {
  sender: TSender;
  text?: string;
  image?: string;
  timestamp?: Date;
}

export type TriggerId = string;

export interface ITrigger {
  id: TriggerId;
  botMessages?: IMessage[];
  textSuggestions?: { text: string; goto: TriggerId }[];
  imageReactions?: { path: string; goto: TriggerId }[]; // urls
  // goto: { matchText?: string; matchImage?: string; next: TriggerId }[];
  goto?: TriggerId;
  specials?: string[];
}

export interface IBot {
  name: string;
  active: boolean;
  avatar: string;
  backgrounds?: string[];
}

export interface IChatContext {
  bots: IBot[];
  useChat: (botName: string) => IChatHandler;
  userName: string;
  setUserName: (name: string) => void;
}

export interface IChatHandler {
  botName: string;
  bot?: IBot;
  activeTrigger?: ITrigger;
  activeBotMessage?: IMessage;
  botIsTyping: boolean;
  processUserMessage: (msg: IMessage) => void;
  reset: () => void;
  showSharePanel: boolean;
}

const ChatContext = createContext<IChatContext | undefined>(undefined);

export const ChatProvider: React.FC = ({ children }) => {
  // const [bots, set] = useState(["Karen", "About"])
  const bots = [
    {
      name: "Karen",
      active: true,
      avatar: AvatarKaren,
      backgrounds: [
        BackgroundKaren1,
        BackgroundKaren2,
        BackgroundKaren3,
        BackgroundKaren4,
      ],
    },
    { name: "Doomer girl", active: false, avatar: AvatarDoomergirl },
    { name: "SJW", active: false, avatar: AvatarSJW },
    { name: "Covidiot", active: false, avatar: AvatarCovidiot },
    {
      name: "About",
      active: true,
      avatar: AvatarAbout,
      backgrounds: [BackgroundAbout],
    },
  ];
  const [userName, setUserName] = useStorageState(
    localStorage,
    "userName",
    "Me"
  );

  const useChat = (botName: string): IChatHandler => {
    return ChatHandler(
      botName,
      bots.find(({ name }) => name === botName)
    );
  };

  return (
    <ChatContext.Provider
      value={{
        bots,
        useChat,
        userName,
        setUserName,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => useContext(ChatContext)!;

export default ChatContext;
