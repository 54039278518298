import { useState, useMemo, useEffect } from "react";
import randomInt from "../randomInt";
import {
  IChatHandler,
  TriggerId,
  ITrigger,
  IMessage,
  useChatContext,
  TSender,
  IBot,
} from "./ChatContext";

import KarenTriggersJSON from "../assets/triggers/Karen.json";
import AboutTriggersJSON from "../assets/triggers/About.json";

const processTrigger = (tr: any): ITrigger => {
  let result: ITrigger = {
    ...tr,
    botMessages: undefined,
  };
  if (tr.botMessage) {
    result.botMessages = [{ text: tr.botMessage, sender: "bot" as TSender }];
  } else if (tr.botMessages) {
    result.botMessages = tr.botMessages.map((msg: any) => {
      if (typeof msg === "string") {
        return { text: msg, sender: "bot" as TSender };
      } else {
        return { ...msg, sender: "bot" as TSender };
      }
    });
  }
  // result.goto = (result.goto || []).map((jump) => {
  //   return { ...jump, matchText: jump.matchText?.toLowerCase() };
  // });
  return result;
};
const KarenTriggers = KarenTriggersJSON.map(processTrigger);
const AboutTriggers = AboutTriggersJSON.map(processTrigger);

const PauseMessages: IMessage[] = [
  { sender: "bot", text: "Hmmm" },
  { sender: "bot", text: "Pfff" },
  { sender: "bot", text: "Sorry?" },
];

const ChatHandler = (botName: string, bot?: IBot): IChatHandler => {
  const [activeTriggerId, setActiveTriggerId] = useState<TriggerId>("start");
  const [activeTrigger, setActiveTrigger] = useState<ITrigger>();
  const [botMessageIdx, setBotMessageIdx] = useState(0);
  const [activeBotMessage, setActiveBotMessage] = useState<
    IMessage | undefined
  >();
  const [botIsTyping, setBotIsTyping] = useState(true);
  const [showSharePanel, setShowSharePanel] = useState(false);
  const [pauseMsgSeqCount, setPauseMsgSeqCount] = useState(0);

  const { setUserName } = useChatContext();

  const triggers: ITrigger[] = useMemo(() => {
    return botName === "Karen" ? KarenTriggers : AboutTriggers;
  }, [botName]);

  useEffect(() => {
    const next = triggers.find((trigger) => trigger.id === activeTriggerId);
    setBotIsTyping(true);
    setActiveTrigger(next);
    setBotMessageIdx(0);
    setPauseMsgSeqCount(0);
  }, [activeTriggerId, triggers]);

  useEffect(() => {
    setShowSharePanel(!!activeTrigger?.specials?.includes("shareLink"));
  }, [activeTrigger]);

  useEffect(() => {
    if (activeTrigger?.botMessages?.length) {
      if (botMessageIdx < activeTrigger.botMessages.length) {
        setBotIsTyping(true);
        let maxTime = 3000;
        if (process.env.NODE_ENV === "development") {
          maxTime = 300;
        }
        if (activeTrigger?.botMessages[botMessageIdx]?.text) {
          if (activeTrigger?.botMessages[botMessageIdx]?.text!.length > 25) {
            maxTime *= 1.2;
          } else if (
            activeTrigger?.botMessages[botMessageIdx]?.text!.length < 15
          ) {
            maxTime *= 0.75;
          }
        }
        setTimeout(() => {
          setActiveBotMessage(activeTrigger!.botMessages![botMessageIdx]);
          setBotMessageIdx((idx) => idx + 1);
        }, randomInt(maxTime / 3, maxTime));
      } else if (
        activeTrigger?.specials?.includes("skipUserReaction") &&
        activeTrigger?.goto
      ) {
        setBotIsTyping(false);
        setActiveTriggerId(activeTrigger?.goto);
      } else {
        setBotIsTyping(false);
      }
    } else {
      setBotIsTyping(false);
      setActiveBotMessage(undefined);
    }
  }, [activeTrigger, botMessageIdx]);

  const processUserMessage = (msg: IMessage) => {
    if (!activeTrigger) {
      // setActiveTriggerId("start");
      return;
    }
    if (activeTrigger?.specials?.includes("getUserName")) {
      if (msg.text) {
        let name = msg.text.trim();
        name = name.charAt(0).toUpperCase() + name.substring(1);
        setUserName(name);
      }
    }
    let match = undefined;
    if (msg.text) {
      match = activeTrigger.textSuggestions?.find(
        ({ text }) =>
          msg.text?.trim().toLowerCase() === text.toLowerCase().trim()
      );
    } else if (msg.image) {
      match = activeTrigger.imageReactions?.find(
        ({ path }) => msg.image === path
      );
    }
    if (match?.goto) {
      setActiveTriggerId(match.goto);
      return;
    }
    if (!activeTrigger.textSuggestions && activeTrigger.goto) {
      setActiveTriggerId(activeTrigger.goto);
      return;
    }
    // no match, throw in a 'hmm' pause message
    setPauseMsgSeqCount((count) => count + 1);
  };

  useEffect(() => {
    if (pauseMsgSeqCount > 0) {
      setBotIsTyping(true);
      setTimeout(() => {
        setBotIsTyping(false);
        setActiveBotMessage((curMsg) => {
          const newPausMsgs = PauseMessages.filter((msg) => msg !== curMsg);
          return newPausMsgs[randomInt(0, newPausMsgs.length)];
        });
        if (pauseMsgSeqCount === 3) {
          // force reload
          setPauseMsgSeqCount(0);
          setBotMessageIdx((idx) => Math.max(idx - randomInt(1, 3), 0));
        }
      }, randomInt(500, 1500));
    }
  }, [pauseMsgSeqCount]);

  const reset = () => {
    setActiveTriggerId("start");
  };

  return {
    botName,
    bot,
    botIsTyping,
    activeTrigger,
    activeBotMessage,
    processUserMessage,
    reset,
    showSharePanel,
  };
};

export default ChatHandler;
