import React, { CSSProperties } from "react";

type Props = {
  src: string;
  duration?: number;
  className?: string;
  timingFunction?: string;
  delay?: number;
  alt?: string;
  style?: CSSProperties;
};

type State = {
  topSrc: string;
  bottomOpacity: number;
  bottomSrc: string;
  bottomClass: string;
};

class CrossFadeImage extends React.Component<Props, State> {
  public static defaultProps: Partial<Props> = {
    delay: 0,
    duration: 500,
    timingFunction: "ease",
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      bottomOpacity: 0,
      bottomSrc: props.src,
      topSrc: props.src,
      bottomClass: "",
    };
  }

  public componentDidUpdate(prevProps: Props) {
    if (!prevProps.src) {
      this.setState({ topSrc: this.props.src, bottomClass: "FadeInit" });
      return;
    }
    if (prevProps.src !== this.props.src) {
      this.setState({ topSrc: prevProps.src }, () => {
        this.setState({ bottomSrc: "", topSrc: prevProps.src }, () => {
          setTimeout(() => {
            this.setState({
              bottomSrc: this.props.src, //prevProps.src,
              //   topSrc: prevProps.src, //this.props.src,
              bottomOpacity: 0,
              bottomClass: "",
            });
          }, (this.props.delay || 150) / 2);
          setTimeout(() => {
            this.setState({ bottomOpacity: 0.99, bottomClass: "FadeIn" });
          }, this.props.delay || 150);
        });
      });
    }
  }

  public render() {
    const {
      duration,
      timingFunction,
      delay,
      style,
      alt,
      className,
    } = this.props;
    const { topSrc, bottomOpacity, bottomSrc, bottomClass } = this.state;

    return (
      <div
        // style={{ maxWidth: "100%", maxHeight: "100%" }}
        className={className}
      >
        {true && (
          <img
            style={{
              //   maxWidth: "100%",
              //   maxHeight: "100%",
              //   position: "absolute",
              ...style,
            }}
            key="top"
            src={topSrc}
            alt={alt}
            // onLoad={() => this.onLoad()}
            onError={(e) => this.onError(topSrc, e)}
          />
        )}
        {bottomSrc && (
          <img
            alt="bottom"
            className={`CrossFadeBottom ${this.state.bottomClass}`}
            style={{
              //   maxHeight: "100%",
              //   maxWidth: "100%",
              //   opacity: bottomOpacity,
              //   transition: `opacity ${duration! / 1000}s ${timingFunction!} ${
              //     0 / 1000
              //   }s`,
              ...style,
            }}
            src={bottomSrc}
          />
        )}
      </div>
    );
  }

  private onLoad() {
    this.setState({ bottomOpacity: 0 });
  }

  private onError(
    src: string,
    error: React.SyntheticEvent<HTMLImageElement, Event>
  ) {
    console.warn(`There is an error with ${src}: ${error}`);
  }
}

export default CrossFadeImage;
